import getApolloConfig from '../common/js/getApolloConfig'
import carModelsJson from './carModels.json'
import channelsJson from './channels.json'
import store from '../www/vuex/index'
//用户类型
export const userTypes = [
    {value: '0', label: '总部'},
    {value: '1', label: '供应商'},
    {value: '2', label: '运营商'},
    {value: '9', label: '中山陵管理员'},
]

//车型车座
export let seatModelList = carModelsJson;
//产品类型
export const productFormList = [
    {value: 2, name: '按日包车', useCar: '1', productType: 2},
    {value: 3, name: '线路包车', useCar: '1', productType: 2},
    {value: 4, name: '精致小团', useCar: '0', productType: 3},
    {value: 5, name: '车+X', useCar: '1', productType: 3},
    {value: 6, name: '特色体验', useCar: '1', productType: 3},
    {value: 7, name: '接机', useCar: '1', productType: 1},
    {value: 8, name: '送机', useCar: '1', productType: 1},
    {value: 9, name: '接站', useCar: '1', productType: 1},
    {value: 10, name: '送站', useCar: '1', productType: 1},
    {value: 11, name: '门票', useCar: '0', productType: 3},
    {value: 12, name: '接送服务', useCar: '1', productType: 1},
    {value: 14, name: '即时预约接送服务', useCar: '1', productType: 7},
    {value: 15, name: '即时预约按日包车', useCar: '1', productType: 7},
    {value: 16, name: '一日游', useCar: '0', productType: 3},
    {value: 17, name: '多日游', useCar: '0', productType: 3},
    {value: 18, name: '向导服务', useCar: '0', productType: 3},
    {value: 21, name: '包车游', useCar: '1', productType: 3},
    {value: 24, name: '一键叫车', useCar: '1', productType: 3},
    {value: 22, name: '短驳接送', useCar: '1', productType: 3},
    {value: 26, name: '合作用车', useCar: '1', productType: 3},
    {value: 27, name: '接船', useCar: '1', productType: 1},
    {value: 28, name: '送船', useCar: '1', productType: 1},
    {value: 29, name: '实时用车', useCar: '1', productType: 1},
];

//资源类型
export const resourceTypeList = [
    {value: 2, name: '按日包车', useCar: '1', productType: 2},
    {value: 3, name: '线路包车', useCar: '1', productType: 2},
    {value: 4, name: '精致小团', useCar: '0', productType: 3},
    {value: 5, name: '车+X', useCar: '1', productType: 3},
    {value: 6, name: '特色体验', useCar: '1', productType: 3},
    {value: 7, name: '接机', useCar: '1', productType: 1},
    {value: 8, name: '送机', useCar: '1', productType: 1},
    {value: 9, name: '接站', useCar: '1', productType: 1},
    {value: 10, name: '送站', useCar: '1', productType: 1},
    {value: 11, name: '门票', useCar: '0', productType: 3},
    {value: 12, name: '接送服务', useCar: '1', productType: 1},
    {value: 16, name: '一日游', useCar: '0', productType: 3},
    {value: 17, name: '多日游', useCar: '0', productType: 3},
    {value: 18, name: '向导服务', useCar: '0', productType: 3},
    {value: 19, name: '即时预约接送服务', useCar: '1', productType: 7},
    {value: 20, name: '即时预约按日包车', useCar: '1', productType: 7},
    {value: 21, name: '包车游', useCar: '1', productType: 3},
];

//订单状态
export const orderStatusList = [
    {name: '新订待分配', value: '21'},
    {name: '新订待确认', value: '22'},
    {name: '供应商拒绝', value: '23'},
    {name: '待分配司导', value: '31'},
    {name: '抢单中', value: '32'},
    {name: '待出行', value: '41'},
    {name: '司机已就位', value: '42'},
    {name: '司机准备中', value: '43'},
    {name: '进行中', value: '51'},
    {name: '已完成', value: '61'},
    {name: '取消中', value: '71'},
    {name: '已取消', value: '72'}
];

export let channel = channelsJson;

// 渠道类型
export const channelList = [
    {
        "value": 1,
        "label": "携程"
    },
    {
        "value": 2,
        "label": "900"
    },
    {
        "value": 3,
        "label": "上海邮轮码头"
    },
    {
        "value": 4,
        "label": "尚游"
    },
    {
        "value": 5,
        "label": "沈阳南航"
    },
    {
        "value": 6,
        "label": "同城用车"
    },
    {
        "value": 7,
        "label": "TDS杭州用车"
    },
    {
        value: 11,
        label: 'TDS邮轮码头'
    },
    {
        "value": 8,
        "label": "飞猪"
    },
    {
        "value": 9,
        "label": "南航"
    },
    {
        "value": 10,
        "label": " 航旅"
    }
]

// 证件类型
export const certificateList = [
    {value: 1, label: '身份证'},
    {value: 2, label: '港澳通行证'},
    {value: 3, label: '护照（外籍）'},
    {value: 4, label: '军官证'},
    {value: 5, label: '士兵证'},
    {value: 6, label: '台胞证'},
    {value: 7, label: '导游证'}
];

export const certificateItem = [
    {value: 1, label: '身份证'},
    {value: 20, label: '港澳通行证'},
    {value: 2, label: '护照（外籍）'},
    {value: 4, label: '军官证'},
    {value: 5, label: '士兵证'},
    {value: 8, label: '台胞证'},
    {value: 26, label: '导游证'}
];

// 报价类型
export const quotationTypeList = [
    {value: 0, label: '按里程报价'},
    {value: 1, label: '一口价'},
    {value: 2, label: '包车计价'},
];

export const quotationProductionTypes = [
    {value: 7, label: '接机'},
    {value: 8, label: '送机'},
    {value: 9, label: '接站'},
    {value: 10, label: '送站'},
    {value: 12, label: '接送服务'},
    {value: 27, label: '接船'},
    {value: 28, label: '送船'},
    {value: 29, label: '实时用车'},
]

// 优惠券 业务类型
export const couponsBusinessTypes = [
    {value: 12, label: '接送服务'},
    {value: 7, label: '接机'},
    {value: 8, label: '送机'},
    {value: 9, label: '接站'},
    {value: 10, label: '送站'},
    {value: 2, label: '按日包车'},
    {value: 16, label: '一日游'},
    {value: 17, label: '多日游'},
    {value: 29, label: '实时用车'},
]

//是否跨城
export const crossCitys = [
    {value: 0, label: '不跨城'},
    {value: 1, label: '跨城'}
]

//banner type
export const bannerTypes = [
    {
        label: '用车',
        value: 0
    },
    {
        label: '旅游',
        value: 1
    }
]


//获取车型车座
export const getSeatModel = (val) => seatModelList.find(item => parseInt(val) === item.value);

//获取渠道
export const getChannel = () => channel;

// 获取产品类型
export const getSeatProductForm = (val) => productFormList.find(item => parseInt(val) === item.value);


export const rechargeProductTypes = [
    {
        label: '技术服务',
        value: 1
    },
    {
        label: '代叫车服务',
        value: 0
    }
]

export const typeOfCharterCarOptions = [
    {
        label: '半日包车4小时50公里',
        value: 999,
        hour: 4,
        distance: 50
    },
    {
        label: '半日包车4小时100公里',
        value: 998,
        hour: 4,
        distance: 100
    },
    {
        label: '全日包车8小时100公里',
        value: 997,
        hour: 8,
        distance: 100
    },
    {
        label: '全日包车8小时200公里',
        value: 1,
        hour: 8,
        distance: 200
    },
    {
        label: '全日包车8小时300公里',
        value: 993,
        hour: 8,
        distance: 300
    },
    // {
    //     label: '全日包车9小时300公里',
    //     value: -1,
    //     hour: 9,
    //     distance: 300
    // },
    {
        label: '全日包车10小时100公里',
        value: 996,
        hour: 10,
        distance: 100
    },
    // {
    //     label: '全日包车10小时150公里',
    //     value: -2,
    //     hour: 10,
    //     distance: 150
    // },
    {
        label: '全日包车10小时200公里',
        value: 995,
        hour: 10,
        distance: 200
    },
    {
        label: '全日包车10小时300公里',
        value: 994,
        hour: 10,
        distance: 300
    },
    {
        label: '全日包车10小时500公里',
        value: 992,
        hour: 10,
        distance: 500
    }

]


//获取apollo数据
export const getApolloConfigOptions = async () => {
    try {
        const ret = await getApolloConfig('options_config');
        if (ret) {
            const {carModels, channels} = ret
            seatModelList = carModels;
            channel = channels;
            store.commit('CHANGE_CHANNEL', channels)
            store.commit('CHANGE_SEAT_MODEL_LIST', carModels)
        }
    } catch (e) {
        console.log(e);
    }
}

